
input#button-checkbox.mobile {
    opacity: 0;
    width: 48px;
    height: 100%;
    margin: 0;
    z-index: 100;
    display: none;
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
    input#button-checkbox.mobile {
        display: block;
    }
    input {
        cursor: pointer;
    }
    #button {
        position: relative;
        width: 48px;
        height: 48px;
    }
    #button span {
        left: 0;
        right: 0;

        margin: 0 auto;

        position: absolute;
        width: 32px;
        height: 3px;
        color: black;
        background-color: var(--near-black);

        z-index: -1;
    }

    #button span:nth-last-child(1) {
        top: 12px;
    }
    #button span:nth-last-child(2) {
        top: 0px;
        bottom: 0px;
        margin: auto;
    }
    #button span:nth-last-child(3) {
        bottom: 12px;
    }
}