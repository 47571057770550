#cards {
    align-self: start;
    grid-column: 1/span 3;

    margin-top: 48px;

    position: relative;
}

#cards-heading {
    margin: 16px 0;
    font-size: 28px;
    font-weight: 700;
    line-height: 26px;
}

[class|=card-image] img {
    object-fit: cover;
    width: 100%;
    height: 100%;

    position: relative;
    display: block;
}

[class|=card-group] {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: dense;
    gap: 32px;

    padding: 16px 0;

    position: relative;
}

[class|=card-base] {
    justify-self: stretch;
    align-self: stretch;
    
    height: 272px;
    position: relative;

    display: flex;
    justify-content: space-between;
}

[class|=card-base] [class|=card-title] {
    transition: 0.3s;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0);
}

[class|=card-base]:hover [class|=card-title] {
    text-decoration-color: var(--purple);
}

[class|=card-image] {
    background-color: var(--light-gray);
}

[class|=card-text] {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

[class|=card-title] { 
    font-weight: 600;
    color: var(--near-black);
}

[class|=card-title].title-xs {
    font-size: 36px;
    line-height: 40px;
}

[class|=card-title].title-sm {
    font-size: 36px;
    line-height: 40px;
}

[class|=card-title].title-md {
    font-size: 38px;
    line-height: 40px;
}

[class|=card-title].title-lg {
    font-size: 40px;
    line-height: 40px;
}

[class|=card-title].title-xl {
    font-size: 48px;
    line-height: 52px;
}

[class|=card-date] {
    font-weight: 500;
    color: var(--gray);
    font-size: 24px;
    line-height: 24px;
}

[class|=card-sources] {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: var(--gray);

    height: auto;

    display: flex;
    align-items: center;

    margin-top: 4px;
}

.card-base-sm {
    grid-column: auto / span 1;

    flex-direction: column;
}

.card-image-sm {
    height: 55%;
}

.card-text-sm {
    height: 43%;
}

.card-title-sm.title-xs {
    font-size: 16px;
    line-height: 18px;
}

.card-title-sm.title-sm {
    font-size: 17px;
    line-height: 20px;
}

.card-title-sm.title-md {
    font-size: 20px;
    line-height: 22px;
}

.card-title-sm.title-lg {
    font-size: 21px;
    line-height: 24px;
}

.card-title-sm.title-xl {
    font-size: 26px;
    line-height: 27px;
}

.card-date-sm {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
}

.card-sources-sm {
    display: none;
}

[class|=card-base-md] {
    grid-column: auto / span 3;
}

.card-base-md-1 {
    flex-direction: row;
}

.card-base-md-2 {
    flex-direction: row-reverse;
}

[class|=card-image-md] {
    height: 100%;
    width: 42%;
}

.card-text-md-2 {
    text-align: right;
}

[class|=card-text-md] {
    width: 50%;
    padding: 0 4%;
}

.card-sources-md-2 {
    justify-content: flex-end;
}

.card-base-lg {
    grid-column: auto / span 2;
    grid-row: auto / span 2;
    height: 576px;

    flex-direction: column;
}

.card-image-lg {
    height: 55%;
}

.card-text-lg {
    height: 43%;
}

/* ASTHETIC SEPARATORS */

[class|="card"]::before, [class|="card"]::after {
    content: "";

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    z-index: -1;

    border-color: var(--light-gray);
    border-width: 1px;
}

[class|=card-base-md]:first-child:before {
    bottom: -16px;
    border-bottom-style: solid;
}

[class|=card-group]:nth-last-of-type(2n + 2)::before {
    top: 16px;
    right: -16px;
    bottom: 16px;
    border-right-style: solid;
}
[class|=card-group]:nth-last-of-type(2n + 1)::before {
    top: 16px;
    left: -16px;
    bottom: 16px;
    border-left-style: solid;
}

.card-group-1 .card-base-lg::before {
    right: -16px;
    border-right-style: solid;
}

[class|=card-group]::after {
    border-top-style: solid;
}

.card-group-3 .card-base-lg::before {
    left: -16px;
    border-left-style: solid;
}

/* MOBILE */

@media only screen and (max-width: 992px) {
    [class|=card-group] {
        grid-template-columns: auto;
    }
    [class|=card-base] {
        grid-column: 1 / 1;
        grid-row: auto / span 1;

        display: flex;
        flex-direction: row;
        padding: 0;
        height: auto;
    }
    [class|=card-image] img {
        height: 100%;
        width: 100%;
        display: block;
    }
    [class|=card-image] {
        width: 45%;
        height: 100%;
    }
    [class|=card-text] {
        text-align: left;
        margin: 0 3%;
        padding: 0;
        width: 59%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    #cards [class|=card-title] {
        font-size: 28px;
        line-height: 34px;
    }
    [class|=card-date] {
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
    }
    [class|=card-sources] {
        font-size: 14px;
        line-height: 17px;

        display: block;
        height: auto;
    }
    #cards [class|="card"]::before, [class|="card"]::after {
        display: none;
    }
    #cards [class|="card-base"]::before {
        display: block;
        top: -16px;
        right: 0px;
        left: 0px;
        border-top-style: solid;
        border-bottom-style: none;
        border-right-style: none;
        border-left-style: none;
    }
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
    [class|=card-base] {
        flex-direction: column;
        height: auto;
    }
    [class|=card-image] {
        width: 100%;
        height: 50vw;
    }
    [class|=card-text] {
        margin: 0;
        width: 100%;
    }
    [class|=card-title] {
        margin-top: 6px;
    }
    [class|=card-date] {
        margin-top: 4px;
    }
    [class|=card-sources] {
        margin-top: 4px;
    }
}