#headlines {
    top: 48px;
    position: -webkit-sticky;
    position: sticky;
    
    color: var(--near-black);
    
    height: auto;
}

#headlines-label {
    margin: 16px 0;
    
    font-size: 28px;
    line-height: 26px;
    font-weight: 600;
}

.headline-title {
    padding: 16px 0;
    display: block;

    font-size: 14px;
    line-height: 16px;
    font-weight: 500;

    border-top-style: solid;
    border-width: 1px;
    border-color: var(--light-gray);

    transition: 0.3s;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0);
}

.headline-title:hover {
    text-decoration-color: var(--purple);
}

/* mobile */

@media only screen and (max-width: 992px) {
    #headlines {
      display: none;
    }
}