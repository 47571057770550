:root {
    --body-width: 1172px;
    --footer-height: 36px;
    
    --purple: #8A2BE9;
    --black: #0E1212;
    --near-black: #292929;
    --gray: #666666;
    --light-gray: #E9E9E9;

    --blue: hsl(211, 81%, 54%);
    --light-blue: hsl(211, 81%, 70%);
    --light-purple: hsl(270, 81%, 70%);
    --light-red: hsl(11, 81%, 70%);
    --red:hsl(11, 81%, 54%);

    --positive: hsl(130, 50%, 54%);
    --negative: hsl(0, 81%, 54%);
}

body {
    margin: 0;
}

button {
    background: none;
    border: none;
}

html {
    overflow-y: scroll;
    height: 100%;
}

a {
    text-decoration: none;
    color: inherit;
}

.mobile {
    display: none
}

@media only screen and (max-width: 992px) {
    .mobile {
        display: block;
    }
}