.dropdown {
    position: relative;

    width: 128px;
    height: 100%;
    display: block;

    z-index: 10;

}

.dropdown-choice {
    font-family: Archivo;
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    color: var(--near-black);

    padding: 0 4px;

    border-bottom-style: solid;
    border-width: 1px;
    border-color: var(--gray);
}

.dropdown-icon {
    height: 28px;
    float: right;
}

.dropdown-options {
    width: 128px;
    position: absolute;
    background-color: white;

    box-shadow: 0px 12px 12px rgb(0 0 0 / 5%)
}

.dropdown-option {
    font-family: Archivo;
    font-size: 18px;
    font-weight: 500;
    color: var(--near-black);

    display: block;
    padding: 0 8px;

    width: 100%;
    height: 28px;

    text-align: left;
}

.dropdown-option.active {
    font-weight: 600;
    background-color: rgb(245, 245, 245);
}