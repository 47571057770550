header {
    position: fixed;
    height: 48px;
    left: 0px;
    right: 0px;
    top: 0px;

    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);

    z-index: 9999;

    line-height: 32px;
    font-family: Archivo;
    font-weight: 600;
    font-size: 36px;
    color: var(--black)
}

#inner-header-container {
    max-width: 1172px;
    height: inherit;

    margin: auto;
    padding: 0 16px; 

    display: flex;
    justify-content: space-between;
    align-items: center;

    z-index: 9999;
}

#logo {
    white-space: nowrap;
}

#purple {
    color: var(--purple);
    font-weight: bold;
}

.page-link {
    font-size: 24px;
}

.page-links {
    display: flex;
    justify-content: flex-end;
    column-gap: 32px;
    grid-template-columns: repeat(3, auto);
    width: 100%;
    margin-left: 32px;
}

@media only screen and (max-width: 992px) {
    #logo {
        white-space: normal;
        font-size: 16px;
        line-height: 16px;
        width: min-content;
    }
    .page-links.mobile {
        display: none;
    }
}

@media only screen and (max-width: 768px) and (orientation: portrait) {

    .page-links {
        display: none;
        position: fixed;
    }
    .page-links.mobile {
        display: grid;
        position: fixed;
        top: 47px;
        right: 0;
        width: 100%;
        background-color: white;

        grid-template-columns: 1fr;

        box-shadow: 0px 12px 12px  rgba(0, 0, 0, 0.05);
    }

    .page-link {
        text-align: left;
        box-sizing: border-box;
        padding-left: 32px;
        font-size: 24px;
        line-height: 36px;
        height: 36px;
    }
    
    input#button-checkbox:not(:checked) + .page-links {
        display: none;
    }
}
