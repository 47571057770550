#about {
    max-width: var(--body-width);

    margin: auto;
    padding: 48px 16px; 
}

.about-title {
    font-family: Archivo;
    font-weight: 600;
    font-size: 64px;
    color: var(--black);

    margin: 32px 0;
}

.about-description {
    font-family: Montserrat;
    font-size: 36px;
    color: var(--gray);

}

.purple {
    color: var(--purple);
}

.blue {
    color: var(--blue);
}

.light-blue {
    color: var(--light-blue)
}

.light-purple {
    color: var(--light-purple)
}

.light-red {
    color: var(--light-red)
}

.red {
    color: var(--red);
}

.bold {
    font-weight: bold;
}

#about a {
    font-weight: bold;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0);
    transition: 0.3s;
}

#about a:hover {
    text-decoration-color: var(--gray);
}

.split {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
}

@media only screen and (max-width: 992px) {
    .split {
        grid-template-columns: 1fr;
    }
}