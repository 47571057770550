

#articles-container{
    max-width: var(--body-width);

    margin: auto;
    padding: 0 16px; 

    display: grid;
    grid-template-columns: repeat(8, 1fr);
    column-gap: 32px;
    align-items: start;

    min-height: calc(100vh - var(--footer-height));

    font-family: Archivo;
    color: var(--near-black)
}

#heading {
    font-size: 36px;
    font-weight: 600;

    margin: 16px 0;
}

#articles {
    align-self: start;
    grid-column: 2/span 6;

    margin-top: 48px;
}

.article {
    justify-self: stretch;
    align-self: stretch;

    margin: 32px 0;

    position: relative;

    display: flex;
    justify-content: space-between;
}

.left-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.image-title-container {
    display: flex;
    align-items: center;
}

.article img {
    object-fit: cover;
    height: 90px;
    width: 135px;
    display: block;
}

.article-image {
    margin-right: 16px;
}

.article-title {
    vertical-align: middle;

    font-size: 24px;
    font-weight: 600;
}

.article:hover .article-title {
    text-decoration-color: var(--purple);
}

.article .article-title {
    transition: 0.3s;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0);
}

.article-description {
    color: var(--gray);

    font-family: Montserrat;
    font-size: 18px;

    margin: 16px 0;
}

.date-company-container {
    color: var(--gray);
    font-size: 18px;
    font-weight: 600;
}

.article-company {
    font-weight: 400;
}

.article-bias {
    color: white;
    font-weight: 600;

    padding: 0 0.25em;
}

.l {
    background-color: var(--blue);
}

.cl {
    background-color: var(--light-blue);
}

.c {
    background-color: var(--light-purple);
}

.cr {
    background-color: var(--light-red);
}

.r {
    background-color: var(--red);
}

.right-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    align-items: stretch;

    font-size: 24px;
    font-weight: 500;

    text-align: center;
}

.sentiment-container {
    margin: 0 16px;
}

.article-sentiment {
    font-weight: 700;
}

.emotion-container {
    margin: 0 16px;
}

.article-emotion {
    font-weight: 700;
}

.positive {
    color: var(--positive);
}

.negative {
    color: var(--negative);
}

.article::before {
    content: "";

    position: absolute;
    left: 0;
    right: 0;
    top: -16px;
    bottom: 0;

    z-index: -1;

    border-color: var(--light-gray);
    border-width: 1px;

    
    border-top-style: solid;
}

@media only screen and (max-width: 992px) {
    #articles-container {
        grid-template-columns: repeat(16, 1fr);
        column-gap: 0px;
    }
    #articles {
        grid-column: 2/span 14;
    }
    .article-description {
        display: none;
    }
    .date-company-container {
        margin: 10px 0;
    }
}

@media only screen and (max-width: 768px)  and (orientation: portrait){
    #articles {
        grid-column: 1/span 16;
    }
    .image-title-container {
        flex-direction: column;
    }
    .article img {
        height: 50vw;
        width: 100%;
    }
    .article-image {
        width: 100%;
        margin: 0;
    }
    .article {
        flex-direction: column;
        margin-left: 4px;
        margin-right: 4px;
    }
    .right-container {
        flex-direction: row;
    }
    .article-title {
        margin-top: 10px;
    }
}