#home {
    max-width: var(--body-width);

    margin: auto;
    padding: 0 16px; 

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 32px;
    align-items: start;

    font-family: Archivo;
}

/* mobile */
@media only screen and (max-width: 992px) {
    #home {
      grid-template-columns: repeat(3, 1fr);
    }
}