.search {
    font-size: 24px;
    
    width: 100%;
}

.search-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    height: 32px;
    width: auto;
    border-color: var(--near-black);
    border-bottom-style: solid;
    border-width: 1px;
    box-sizing: border-box;

    color: var(--near-black);

    background-color: rgba(0, 0, 0, 0.05);
}

.fa-search {
    height: 100%;
    display: block;
    font-size: 24px;
    float: right;

    cursor: pointer;
}

.ais-SearchBox {
    width: 100%;
}

.ais-SearchBox-form {
    display: flex;
    height: 32px;;
    width: 100%;
}

.ais-SearchBox-input {
    width: 100%;
    height: 100%;
    padding: unset;
    padding-left: 8px;
    border: none;
    vertical-align: top;

    font-size: 18px;
    font-weight: 500;
    font-family: Archivo;
    color: var(--near-black);
    background-color: inherit;

    order: 1;
}

.ais-SearchBox-input:focus {
    outline: none;
}

.ais-SearchBox-reset {
    padding: 0 6px;
    height: 100%;

    order: 2;

}

.ais-SearchBox-resetIcon {
    height: 100%;
}

.ais-SearchBox-submit {
    padding: 0 6px;

    order: 3;

    cursor: pointer;
}

.ais-SearchBox-submit .fa-search {
    font-size: 18px;
}

.ais-SearchBox-input {
    margin: 0;
}

.ais-Hits {
    background-color: white;
    box-shadow: 0px 12px 12px  rgba(0, 0, 0, 0.05);
    width: 100%;
}

.ais-Hits-item {
    font-size: 18px;
    line-height: 20px;
    
    padding: 8px 12px;
}

.ais-Hits-item, .ais-Hits-img {
    display: block;
}

.ais-Hits-item:hover, .ais-Hits-img:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.ais-Highlight {
    font-weight: 400;
    display: block;
}

.ais-Highlight-nonHighlighted {
    color: var(--gray);
}

.ais-Highlight-highlighted {
    color: var(--near-black);
    font-style: normal;
    font-weight: 500;
}

.ais-Hits-img {
    border-top-style: solid;
    border-color: var(--light-gray);
    border-width: 1px;
}

.ais-Hits-img img {
    display: block;
    height: 18px;
    padding: 6px 12px;
}

.ais-Hits-empty {
    padding: 6px 12px;
    font-size: 18px;
    line-height: 20px;
    color: var(--gray);
    font-weight: 500;
}

input[type="search"]::-webkit-search-cancel-button {

    /* Remove default */
    -webkit-appearance: none;
}

@media only screen and (max-width: 992px) {
    .search.mobile {
        display: none;
    }
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
    .search.mobile {
        display: block;
        margin: 0 16px;
    }

    .ais-Hits {
        position: fixed;
        left: 0;
        right: 0;
        top: 48px;
        z-index: 1000;
        box-shadow: 0px 12px 12px  rgba(0, 0, 0, 0.05);
    }
}